
.messageUser {
    background: #85C1E9 ; 
    color: white;
}
.message {
    background: #1A5276;
    color: white;
}
.rs-panel {
    margin-top: 15px;
    margin-bottom: 15px;
}

.rs-panel .rs-panel,.rs-panel-body {
    border-radius: 25px;
}

.rs-panel.chat-container>.rs-panel-body {
    overflow-y: scroll;
     max-height: 55vh;
}

.rs-panel.chat.chat-active .rs-panel-body {
    background-color: rgb(52, 195, 255)!important;
    color: white;
}
.paddingright{
    padding-right: 50px;
    height: auto
}
.paddingleft{
    padding-left: 50px;
}
