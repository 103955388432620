html, body, div {
    font-family: 'CircularStd-Book ☞';
    src: local('CircularStd-Book ☞'), url('/src/assets/css/Fonts/CircularStd-Book.woff') format('woff');
    line-height: 18px;
    word-spacing: -0.4em;
    }

    h1, h2, h3 {
        line-height: 39px !important;
        word-spacing: -4px !important;
    }

    h4, h5 {
        line-height: 25px !important;
        word-spacing: 0px !important;
    }

    h6 {
        padding-bottom: 0rem !important;
        word-spacing: 0px !important;
    }
  
    @font-face {
    font-family: 'CircularStd-Book ☞';
    font-style: normal;
    font-weight: normal;
    src: local('CircularStd-Book ☞'), url('/src/assets/css/Fonts/CircularStd-Book.woff') format('woff');
    }

    @font-face {
    font-family: 'CircularStd-Bold ☞';
    font-style: normal;
    font-weight: normal;
    src: local('CircularStd-Bold ☞'), url('/src/assets/css/Fonts/CircularStd-Bold.woff') format('woff');
    color: grey !important;
    }

    @font-face {
    font-family: 'CircularStd-Black ☞';
    font-style: normal;
    font-weight: normal;
    src: local('CircularStd-Black ☞'), url('/src/assets/css/Fonts/CircularStd-Black.woff') format('woff');
    }

    .bold {
        font-family: CircularStd-Bold ☞;
    }
    
    .heavy {
        font-family: CircularStd-Black ☞;
    }

    .text-forgot {
        position: absolute;
        right: 1.5rem;
        color: blue;
        cursor: pointer;
    }
    .text-forgot:hover {
        color: rgb(10, 10, 175);
        text-decoration: underline;
    }

.divider__space {
    margin: 3rem;
}

.top__space {
    margin: 10rem;
}

.text-primario {
    color: #179BBF !important;
}

.text-secundario {
    color: rgba(255,126,0,1) !important;
}

.panel__startsession {
    background: rgba(0, 0, 0, 0.048);
    border-radius: 20px !important;
    height: 10rem;
    cursor: pointer;
}
.panel__startsession:hover, .panel__startsession:focus {
    background: rgba(0, 0, 0, 0.096);
    border-radius: 20px !important;
    height: 10rem;
    cursor: pointer;
}

.avatar__startsession {
    height: 7.5rem !important;
    width: 7.5rem !important;
}

.topics {
    margin: 0.5rem;
}

.ubicacion__map {
    padding: 1rem;
    border-radius: 50rem;
    position: absolute;
    bottom: 4rem;
    z-index: 10;
    background-color: white;
    font-size: 20px;
}

.videoTag {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -2 !important;
  }
  
  .videoTag__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2 !important;
  }
  
  @media (min-aspect-ratio: 16/9) {
    .videoTag__video {
      height: 300%;
      top: -100%;
    }
  }
  
  @media (max-aspect-ratio: 16/9) {
    .videoTag__video {
      width: 300%;
      left: -100%;
    }
  }

  
.btn-login {
    background: #179BBF !important;
    border-radius: 50px !important;
    padding: 1rem !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    font-size: 18px !important;
    color: white !important;
    width: 100% !important;
} 

.btn-login-redes {
    background: rgb(241, 241, 241) !important;
    border-radius: 50px !important;
    padding: 0.5rem !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;

    width: 100% !important;
} 



.close-window {
    border: 0 solid!important;
    width: 55px;
    height: 55px;
    border-radius: 50% !important;
    padding: 0;
    box-shadow: none !important;
    z-index: 2 !important;
    margin-bottom: 2rem;
  }
  .close-window:hover, .close-window:focus {
    filter: brightness(1.1);
    outline: none;
    background: rgba(0, 0, 0, 0.192);
  }

  .pic__perfil {
      height: 12rem !important;
      width: 12rem !important;
      border-radius: 100rem !important;
      margin-top: 30%;
  }


@media only screen and (max-width: 2900px) {

    .boton-tutorial {
        position:fixed; 
        bottom:7rem;
        right:2rem; 
        z-Index:1;
    }

    .agenda__fecha {
        line-height: -200px !important;
    }

    .navbar {
    height: 5rem;
    background: white;
    margin-top: 2rem;
    margin-left: 10rem;
    margin-right: 10rem;
    border-radius: 50rem;
    z-index: 5 !important;
    }
    .navbar__first {
        width: 98% !important;
    }

    .avatar {
        height: 4.5rem !important;
        width: 4.5rem !important;
    }


    .ficha__startsession h5 {
        margin-bottom: -18px !important;
    }
    .ficha__startsession text {
        color: grey;
    }

    .help {
        margin-top: 0rem;
    } 
    .help__message {
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
    .help__time {
        font-size: 10px;
        word-spacing: -3px;
        position: absolute;
        bottom: 18px;
        right: 20px;
        color: rgba(255, 255, 255, 0.76);
    }
    .help__send {
        background:white !important;
        border:2px solid #179BBF !important;
        color: #179BBF !important;
        border-radius:50%  !important;
        height:55px;
        width:55px;
        cursor:pointer;
        margin-Left:1rem;
        font-size: 2rem !important;
    }
    .help__send:hover {
        background:#179BBF !important;
        border:2px solid #179BBF !important;
        color: white !important;
        border-radius:50%  !important;
        height:55px;
        width:55px;
        cursor:pointer;
        margin-Left:1rem;
        font-size: 2rem !important;
    }
    .help__form {
        background: white;
        padding: 2rem;
        z-index: 5 !important;
    }

    .help__back {
        background:white !important;
        border:2px solid #179BBF !important;
        color: #179BBF !important;
        border-radius:50%  !important;
        height:40px;
        width:40px;
        cursor:pointer;
        margin-Left:0rem;
        font-size: 2rem !important;
        position: absolute;
        left: 1rem;
    }
    .help__back:hover {
        background:#179BBF !important;
        border:2px solid #179BBF !important;
        color: white !important;
        border-radius:50%  !important;
        height:40px;
        width:40px;
        cursor:pointer;
        margin-Left:0rem;
        font-size: 2rem !important;
        position: absolute;
        left: 1rem;
    }

    .register__back {
        background:white !important;
        border:2px solid #179BBF !important;
        color: #179BBF !important;
        border-radius:50%  !important;
        height:40px;
        width:40px;
        cursor:pointer;
        margin-Left:0rem;
        font-size: 2rem !important;
        position: absolute;
        right: 1rem;
    }
    .register__back:hover {
        background:#179BBF !important;
        border:2px solid #179BBF !important;
        color: white !important;
        border-radius:50%  !important;
        height:40px;
        width:40px;
        cursor:pointer;
        margin-Left:0rem;
        font-size: 2rem !important;
        position: absolute;
        right: 1rem;
    }


    .review {
        padding-top: 2rem !important;
    }



    .btn-register {
        margin-top: 1rem;
        margin-bottom: -1rem ;
        padding-top: 0.5rem;
        background: white !important;
        border: 1px solid rgb(163, 163, 163) !important;
        border-radius: 20px;
    }
    .btn-register:hover {
        margin-top: 1rem;
        margin-bottom: -1rem ;
        padding-top: 0.5rem;
        color: white !important;
        background: rgb(224, 224, 224) !important;
        border: 1px solid rgb(224, 224, 224) !important;
        border-radius: 20px;
    }

    .login {
        background-image: url(../src/assets/images/fondo.jpg);
    }

    .review__app {
        background-image: url(../src/assets/images/fondo.jpg);
    }

    .logo__login {
      
        display: block;
        margin: auto;
        height: 5rem;
    }
    .logo {
        height: 3rem;
    }


    .logo__call {
        height: 2.5rem;
        margin-left: 30% !important;
        margin-top: 0.7rem !important;
    }

    .btn {
        background: #179BBF !important;
        border: 0 solid !important;
        border-radius: 100rem !important;
    }

    .btn__go {
        height: 3rem;
        width: 10rem;
        font-size: 20px !important;
        background:
        linear-gradient(119deg, 
        rgba(255,198,141,1) 0%, 
        rgba(255,126,0,1) 100%) !important;
    }

    .btn__schedule {
        background: #179bbf8f !important;
        margin-top: 0.5rem;

    }

    .btn__log__buy {
        margin-bottom: -6rem;
        width: 100%;
        padding: 1rem !important;
        padding-left: 1.5rem !important;
        background: 
        linear-gradient(119deg, 
        rgba(255,198,141,1) 0%, 
        rgba(255,126,0,1) 100%) !important;
    }
    .btn__log__buy:hover {
        background: 
        linear-gradient(119deg, 
        rgb(255, 217, 179) 0%, 
        rgb(255, 144, 32) 100%) !important;
    }

    .btn__switch__rol {
        background: transparent !important;
        border: 2px solid grey !important;
        color: grey !important;
        position: absolute !important;
        right: 1rem;
    }

    .btn__tuto_next {
        background-color: transparent !important;
        color: #179BBF !important;
        border: 2px solid #179BBF !important;

    }

    .home__info {
        background: transparent; 
        }

    .centrado{
        margin-left: auto;
        margin-right: auto;
      }

    .card__avatar {
        width: 100% !important;
        border-radius: 2rem !important;
        height: 30rem;
        -webkit-box-shadow: 0px 0px 101px -21px rgba(0, 0, 0, 0.541);
        -moz-box-shadow: 0px 0px 101px -21px rgba(0,0,0,0.541);
        box-shadow: 0px 0px 101px -21px rgba(0,0,0,0.541);
    }
    .card {
        border-radius: 2rem !important;
    }
    .card-border {
        border-radius: 20px !important;
        background-color: #17191A !important;
    }

    .card__data {
        border-radius: 1rem;
        background: rgba(0, 0, 0, 0.048);
        padding: 1rem;
        margin: 0.5rem;
    }
    .card__data:hover {
        background: rgba(0, 0, 0, 0.096);
    }

    .card__data__log {
        margin-top: 9rem !important;
    }

    .card-bottom {
        position:absolute;
        top: 1rem;
        right: 1rem;
    }

    .avatar-uploader {
        border-radius: 50px !important;
        width: 3rem;
        border: 0 solid;
        height: 3rem !important;
        background: rgba(0, 0, 0, 0.274) !important;
        color: white !important;
    }
    .avatar-uploader:hover {
        background: rgba(0, 0, 0, 0.445) !important;
    }

    .pic-uploader {
        border-radius: 50px !important;
        width: 4rem;
        border: 0 solid;
        height: 4rem !important;
        background: white !important;
        color: #179BBF !important;
        font-size: 25px !important;
    }
    .pic-uploader:hover {
        background: #1081a0 !important;
        color: white !important;
    }


    .titles {
        font-size: 70px;
    }
    .title {
        color: #179BBF;
    }

    .call {
        width: 100%;
        height: 90vh;
        z-index: -1 !important;
        -webkit-box-shadow: 0px 6px 51px -16px rgba(0,0,0,0.61);
        -moz-box-shadow: 0px 6px 51px -16px rgba(0,0,0,0.61);
        box-shadow: 0px 6px 51px -16px rgba(0,0,0,0.61);
        border-radius: 0 0 50px 40px;
    }
    .call__back {
        margin-top: 0.5rem !important;
        margin-bottom: -0.5rem !important;
        z-index: 1 !important;
        color: white;
        padding: 0.5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        background: red !important;
    }
    .call__back:hover {
        background: rgb(156, 7, 7) !important;
    }

    .demo-icon-first {
        margin-right: 0.6rem;
    }

    .btn-show {
        background-color: #177AB3;
        margin: 2rem;
        margin-left: 1.2rem !important;
        width: 25rem;
        height: 5rem;
        padding-top: 0.6rem;
        padding-left: 1rem;
        color: white !important;
        border-radius: 50rem;
    }
    .btn-show:hover {
        background-color: #2291d1;
    }


    .btn-show-alumno {
        background: 
        linear-gradient(119deg, 
            rgba(23,122,179,1) 0%, 
            rgba(12,61,90,1) 100%) !important;
        padding-top: 1rem !important;
        color: white !important;
        border-radius: 50rem !important;
        width: 100% !important;
        border: 2px solid white !important;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;

    }
    .btn-show-alumno:hover {
        background: 
        linear-gradient(119deg, 
            rgb(35, 150, 216) 0%, 
            rgb(20, 91, 133) 100%) !important;
    }

    .btn-show-tutor {
        background: 
            linear-gradient(119deg, 
            rgba(23,122,179,1) 0%, 
            rgba(12,61,90,1) 100%);
        color: white;
        margin-top: 2rem;
        padding-top: 0.6rem !important;
        padding-left: 1rem !important;
        color: white !important;
        border-radius: 50rem !important;
        width: 100% !important;
        align-items: center;
        justify-content: center;
        border: 2px solid white !important;
    }
    .btn-show-tutor:hover {
        background: 
            linear-gradient(119deg, 
            rgb(35, 150, 216) 0%, 
            rgb(20, 91, 133) 100%);
        color: white;
    }

    .btn__go {
        margin-top: 0 !important;
    }

    .btn__accept {
        padding: 0.5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        border-radius: 30px !important;
        background: 
        linear-gradient(119deg, 
        rgba(23,122,179,1) 0%, 
        rgba(12,61,90,1) 100%) !important;
        color: white !important;
    }
    .btn__accept:hover {
        background: 
            linear-gradient(119deg, 
            rgb(35, 150, 216) 0%, 
            rgb(20, 91, 133) 100%);
        color: white;
    }

    .home__hello {
        font-size: 25px !important; 
        color: grey;
    }

    .btn__tags {
        border-radius: 100rem !important;
        background: transparent !important;
        color: #23c2ee !important;
        border: 2px solid #23c2ee !important;
        margin: 0.4rem !important;
    }
    .btn__tags:hover {
        border-radius: 100rem !important;
        background: #23c2ee !important;
        color: white !important;
        border: 2px solid #23c2ee !important;
    }

    .btn__help {
        position: fixed;
        right: 2rem;
        bottom: 2rem;
        height: 4rem;
        width: 4rem;
        border-radius: 100rem !important;
        font-size: 1.7rem !important;
    }
    .btn__help:hover {
        background: #23c2ee !important;
    }

    .btn__balance {
        position: absolute;
        right: 2rem;
        top: 6rem;
        height: 8rem;
        width: 8rem;
        border-radius: 100rem !important;
        box-shadow: 2px 9px 20px 1px rgba(0, 0, 0, 0.466);
        font-size: 1.7rem !important;
        background: #2C4A99 !important;
    }

    .btn__petitions__tutor {
        margin-top: -2rem !important;
        padding: 0.4rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        font-size: 18px !important;
        -webkit-box-shadow: -1px -1px 51px 0px rgba(0,0,0,0.31) !important;
        -moz-box-shadow: -1px -1px 51px 0px rgba(0,0,0,0.31) !important;
        box-shadow: -1px -1px 51px 0px rgba(0,0,0,0.31) !important;
    }

    .btn-navbar-first {
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        color: rgb(73, 73, 73);
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 16px;
        height: 2.5rem;
        border-radius: 100rem !important;
    }
    .btn-navbar-first:hover, .btn-navbar-first:focus {
        background: #179BBF;
        color: white;
        border-radius: 10px;
    }

    .checks {
        margin-left: 0.5rem;
    }

    .btn__balance_alumno {
        border-radius: 50px !important;
        height: 2.5rem; 
        padding: 1rem !important;
        margin-top: -1.5rem !important;
        z-index: 1 !important;
        background: #1caed6 !important;
        color: white !important;
    }
    .btn__balance_alumno:hover {
        background: #1185a5 !important;
    }


    .btn__review__alumno {
        background: #1caed6 !important; 
    }
    .btn__review__alumno:hover {
        background: #0c7a99 !important;
    }

    .form__textarea__review {
        margin-top: 2rem;
        width: 100% !important;
    }

    .switch {
        margin-top: -1.5rem;
        z-index: 1 !important;
    }
    .ant-switch {
        padding: 0px !important;
        padding-left: 30px !important;
        padding-right: 40px !important;
        color: rgb(0, 0, 0) !important;
        font-size: 20px !important;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        -webkit-font-feature-settings: 'tnum';
                font-feature-settings: 'tnum';
        position: relative;
        display: inline-block;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        min-width: 10px !important;
        height: 50px !important;
        line-height: 2px;
        vertical-align: middle;
        background-color: rgb(107, 107, 107) !important;
        border: 0;
        border-radius: 100px;
        cursor: pointer;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
      }
      .ant-switch:focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
                box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      }
      .ant-switch-checked:focus {
        -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
                box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
      .ant-switch:focus:hover {
        -webkit-box-shadow: none;
                box-shadow: none;
      }
      .ant-switch-checked {
        background-color: #179BBF !important;
      }
      .ant-switch-handle {
        position: absolute;
        top: 2px !important;
        left: 1px;
        width: 44px !important;
        height: 44px !important;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
      .ant-switch-handle::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        border-radius: 50px !important;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
                box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        content: '';
      }
      .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 45px - 2px) !important;
      }


    .image04 {
        width: 100%;
        overflow: hidden;
        position: relative;
        -webkit-box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
        -moz-box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
        box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
    }
    .image04 img {
        width: 100%;
        top: 0;
        position: relative;
        left: 0;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        -o-transition: -o-transform 0.3s;
        transition: transform 0.3s;
    }
    .image04 .ovrly {
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    .image04 .buttons {
        position:absolute;
        top: 3.5%;
        right: 4.5%;
    }
    .image04 .buttons .fa {
        background: rgb(256, 256, 256);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 0%;
        border: white !important;
        color: rgba(50, 50, 50, 0.9);
        display: inline-block;
        margin: 0 1px;
        line-height: 65px;
        font-size: 25px;
        text-align: center;
        text-decoration: none;
        width: 60px;
        height: 60px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0;
        padding-right: 0.2rem;
    }
    .image04:hover {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -webkit-box-shadow: 0px 64px 154px -2px rgba(0,0,0,0.49);
        -moz-box-shadow: 0px 64px 154px -2px rgba(0,0,0,0.49);
        box-shadow: 0px 64px 154px -2px rgba(0,0,0,0.49);
    }
    .image04:hover img {
        -webkit-transform: scale(1.4);
        -moz-transform: scale(1.4);
        -ms-transform: scale(1.4);
        -o-transform: scale(1.4);
        transform: scale(1.4);
    }
    .image04:hover .ovrly {
        opacity: 1;
    }
    .image04:hover .buttons .fa {
        opacity: 1;
    }

    .image04__previa {
        width: 100%;
        overflow: hidden;
        position: relative;
        -webkit-box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
        -moz-box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
        box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
    }
    .image04__previa img {
        width: 100%;
        top: 0;
        position: relative;
        left: 0;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        -o-transition: -o-transform 0.3s;
        transition: transform 0.3s;
    }
    
    .copyright {
        position: fixed !important;
        bottom: 1rem !important;
        left: 1rem !important;
    }

    .dropdown__divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid rgba(0, 0, 0, 0.541) !important;
        width: 200px;
    }

    .calendar__sidebar {
        position: fixed !important;
        top: 8rem !important;
        left: 15rem !important;
    }

    .demo-icon-history {
        color: #179BBF;
        font-size: 2rem;
    }

    .container-sm {
        width: 950px !important;
    }

    .log__level {
        margin-top: -3rem;
    }

    .log__pts {
        margin-top: -6rem;
        background: 
            linear-gradient(119deg, 
            rgba(23,122,179,1) 0%, 
            rgba(12,61,90,1) 100%);
        color: white;
    }
    .log__pts:hover {
        background: 
            linear-gradient(119deg, 
           rgb(28, 135, 197) 0%, 
           rgb(21, 96, 139) 100%);
        color: white;
    }

    .log__balance {
        background-color: #177AB3 !important;
        color: white;
        margin-top: 5rem;
    }
    
    .tutos {
        background: transparent !important;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.541);
        -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.541);
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.541);
        width: 30rem;
    }

    .datepicker {
        border-radius: 5px !important;
        height: 3rem;
        width: 13.3rem;
        margin-left: 0.3rem !important;
        font-size: 20px !important;

    }
}

.academy__img__banner {
    width: 100%;
    height: 30rem !important;
    position: fixed !important;
    object-fit: cover;
    z-index: -2 !important;
}

.academy__container__back {
    border-radius: 50px 50px 0 0;
    background: white;
    backdrop-filter: blur(5px) !important;
    height: 40rem;
    padding: 3rem;
    position: absolute;
    top: 18rem;
    left: 0;
    right: 0;
}

.academy__title {
    position: absolute;
    top: 15rem;
    left: 16rem;
    text-shadow: 0px 0px 13px rgba(0,0,0,0.52);
}

.academy__tabs {
    padding-left: 13rem !important;
    margin-top: -3rem !important;
    padding-right: 12rem !important;
}
.academy__tabs span {
    font-size: 18px;
}

.academy__login__img {
    background-image: url("../src/assets/images/fondo.jpg");
}


/* ESTO ES UNA DIVISIÓN PARA LAS PANTALLAS MÓVILES #####################
########################################################################
#######################################################################*/


@media only screen and (max-width: 900px) {
    .boton-tutorial {
        position:fixed; 
        top:1.2rem;
        left:1rem; 
        z-Index:1;
        height: 2rem;
    }
    .modal-recovery {
        width: 100% !important;
    }

    .navbar {
    width: 100%;
    height: 3.7rem;
    background: white;
    margin-top: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    border-radius: 0rem;
    z-index: 5 !important;
    }

    .avatar {
        height: 3rem !important;
        width: 3rem !important;
    }

    .login {
        background: white;
    }

    .logo__login {
        height: 5rem;
    }
    .logo {
        height: 3rem;
    }

    .btn {
        background: #179BBF !important;
        border: 0 solid !important;
        border-radius: 100rem !important;
    }

    .help {
        margin-top: 7rem;
    } 
    .help__send {
        background:white !important;
        border:2px solid #179BBF !important;
        color: #179BBF !important;
        border-radius:50%  !important;
        height:55px;
        width:55px;
        cursor:pointer;
        margin-Left:0rem;
        font-size: 2rem !important;
        margin-top: 2rem;
    }
    .help__send:hover {
        background:#179BBF !important;
        border:2px solid #179BBF !important;
        color: white !important;
        border-radius:50%  !important;
        height:55px;
        width:55px;
        cursor:pointer;
        margin-Left:1rem;
        font-size: 2rem !important;
    }


    .btn__log__buy {
        margin-top: 7rem;
        margin-bottom: -3rem;
        width: 100%;
        padding: 1rem !important;
        padding-left: 1.5rem !important;
        background: 
        linear-gradient(119deg, 
        rgba(255,198,141,1) 0%, 
        rgba(255,126,0,1) 100%) !important;
    }
    .btn__log__buy:hover {
        background: 
        linear-gradient(119deg, 
        rgb(255, 217, 179) 0%, 
        rgb(255, 144, 32) 100%) !important;
    }

    .btn__switch__rol {
        background: transparent !important;
        border: 2px solid grey !important;
        color: grey !important;
        position: absolute !important;
        right: 1rem;
    }

    .btn__petitions__tutor {
        margin-top: -10.5rem !important;
        padding: 1rem !important;
        font-size: 18px !important;
        -webkit-box-shadow: -1px -1px 51px 0px rgba(0,0,0,0.31) !important;
        -moz-box-shadow: -1px -1px 51px 0px rgba(0,0,0,0.31) !important;
        box-shadow: -1px -1px 51px 0px rgba(0,0,0,0.31) !important;
    }

    .btn__tuto_next {
        background-color: transparent !important;
        color: #179BBF !important;
        border: 2px solid #179BBF !important;

    }


    .home__info {
        background: white; 
        margin-Top: 23rem;
        border-radius: 2rem 2rem 0 0;
        -webkit-box-shadow: 4px -48px 61px -28px rgba(0,0,0,0.45);
        -moz-box-shadow: 4px -48px 61px -28px rgba(0,0,0,0.45);
        box-shadow: 4px -48px 61px -28px rgba(0,0,0,0.45);
        margin-bottom: 5rem;
    }
    .home__info h1 {
        margin-top: 1rem;
    }

    .home__info__alumno {
        margin-top: 23rem !important;
    }

    .centrado{
        margin-left: auto;
        margin-right: auto;
      }

    .card__avatar {
        position: fixed !important;
        left: 0;
        right: 0;
        width: 100% !important;
        border-radius: 0 !important;
        height: 25rem !important;
        -webkit-box-shadow: 0px 0px 101px -21px rgba(0, 0, 0, 0.541);
        -moz-box-shadow: 0px 0px 101px -21px rgba(0,0,0,0.541);
        box-shadow: 0px 0px 101px -21px rgba(0,0,0,0.541);
    }
    .card {
        border-radius: 2rem !important;

    }
    .card-border {
        border-radius: 2rem !important;
        background-color: #17191A !important;
    }

    .card__data {
        border-radius: 1rem;
        background: rgba(0, 0, 0, 0.13);
        padding: 1rem;
        margin: 0.5rem;
    }

    .card__data__log {
        margin-top: 7rem !important;
    }

    .log__data__first {
        position: fixed !important;
        left: 0;
        right: 0;
        width: 100% !important;
        background: white;
        padding-bottom: 1rem;
        z-index: 1;
        -webkit-box-shadow: 4px 3px 71px -21px rgba(0,0,0,1);
        -moz-box-shadow: 4px 3px 71px -21px rgba(0,0,0,1);
        box-shadow: 4px 3px 71px -21px rgba(0,0,0,1);
        border-radius: 0 0 30px 30px;
    }
    .log__data_history {
        position: absolute !important;
        top: 16rem !important;
    }
    .log__data_history__alumno {
        position: absolute !important;
        top: 23rem !important;
    }

    .card__data:hover {
        background: rgba(0, 0, 0, 0.096);
    }

    .card-bottom {
        position:absolute;
        top: 1rem;
        right: 1rem;
    }

    .call__back {
        margin-top: 0.5rem !important;
        z-index: 1 !important;
        color: white;
        padding: 0.5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        background: red !important;
    }
    .call__back:hover {
        background: rgb(156, 7, 7) !important;
    }

    .avatar-uploader {
        border-radius: 20px !important;
        height: 2rem !important;
        border: 0 solid;
        height: 2.5rem !important;
        background: rgba(0, 0, 0, 0.274) !important;
        color: white !important;
    }
    .avatar-uploader:hover {
        background: rgba(0, 0, 0, 0.445) !important;
    }

    .titles {
        font-size: 70px;
    }
    .title {
        color: #179BBF;
    }

    .demo-icon-first {
        margin-right: 0.6rem;
    }

    .top__space {
        margin: 0rem;
    }

    .btn-show {
        background-color: #177AB3;
        margin: 2rem;
        margin-left: 0rem !important;
        width: 100%;
        height: 5rem;
        padding-top: 0.6rem;
        padding-left: 1rem;
        color: white !important;
        border-radius: 50rem;
    }
    .btn-show:hover {
        background-color: #2291d1;
    }

    .btn-show-alumno {
        background: 
        linear-gradient(119deg, 
            rgba(23,122,179,1) 0%, 
            rgba(12,61,90,1) 100%) !important;
        margin-top: 2rem;
        padding-top: 1rem !important;
        padding-left: 0rem !important;
        margin-bottom: 1rem ;
        margin-left: 1.2rem;
        margin-right: 1.2rem;
        color: white !important;
        border-radius: 50rem !important;
        width: 100% !important;
        align-items: center;
        justify-content: center;
        border: 2px solid white !important;
    }
    .btn-show-alumno:hover {
        background: 
        linear-gradient(119deg, 
            rgb(35, 150, 216) 0%, 
            rgb(20, 91, 133) 100%) !important;
    }

    .btn-show-tutor {
        background: 
            linear-gradient(119deg, 
            rgba(23,122,179,1) 0%, 
            rgba(12,61,90,1) 100%);
        color: white;
        margin-top: 2rem;
        padding-top: 0.6rem !important;
        padding-left: 1rem !important;
        color: white !important;
        border-radius: 50rem !important;
        width: 100% !important;
        align-items: center;
        justify-content: center;
        border: 2px solid white !important;
    }
    .btn-show-tutor:hover {
        background: 
            linear-gradient(119deg, 
            rgb(35, 150, 216) 0%, 
            rgb(20, 91, 133) 100%);
        color: white;
    }

    .btn__tags {
        border-radius: 100rem !important;
        background: transparent !important;
        color: #23c2ee !important;
        border: 2px solid #23c2ee !important;
        margin: 0.4rem !important;
    }
    .btn__tags:hover {
        border-radius: 100rem !important;
        background: #23c2ee !important;
        color: white !important;
        border: 2px solid #23c2ee !important;
    }

    .btn__help {
        position: fixed;
        right: 2rem;
        bottom: 2rem;
        height: 4rem;
        width: 4rem;
        border-radius: 100rem !important;
        font-size: 1.7rem !important;
    }
    .btn__help:hover {
        background: #23c2ee !important;
    }

    .btn__balance {
        position: absolute;
        right: 4rem;
        top: 5rem;
        height: 8rem;
        width: 8rem;
        border-radius: 100rem !important;
        box-shadow: 2px 9px 20px 1px rgba(0, 0, 0, 0.466);
        font-size: 1.7rem !important;
        background: #2C4A99 !important;
    }

    .btn-navbar-first {
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        color: rgb(73, 73, 73);
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 16px;
        height: 2.5rem;
        border-radius: 100rem !important;
    }
    .btn-navbar-first:hover, .btn-navbar-first:focus {
        background: #179BBF;
        color: white;
        border-radius: 10px;
    }

    .home__hello {
        font-size: 25px !important; 
        color: grey;
    }

    .checks {
        margin-left: 0.5rem;
    }

    .btn__balance_alumno {
        position: absolute !important;
        left: auto;
        right: auto;
        border-radius: 100px !important;
        height: 2.4rem !important; 
        padding: 1.2rem !important;
        margin-top: 22rem !important;
        z-index: 1 !important;
        background: #1caed6 !important;
        color: white !important;
    }
    .btn__balance_alumno:hover {
        background: #1295b9 !important;
    }


    .form__textarea__review {
        margin-top: 2rem;
        width: 100% !important;
    }

    .btn__review {
        width: 100% !important;
        margin: 0;
        position: fixed;
        left: 0;
        right: 0;
        border-radius: 0 !important;
        bottom: 0;
        height: 3rem;
    }

    .btn__review__alumno {
        background: #1caed6 !important; 
    }
    .btn__review__alumno:hover {
        background: #0c7a99 !important;
    }

    .switch {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 21.4rem;
        z-index: 1 !important;
    }
    .ant-switch {
        padding: 0px !important;
        padding-left: 30px !important;
        padding-right: 40px !important;
        color: rgb(0, 0, 0) !important;
        font-size: 20px !important;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        -webkit-font-feature-settings: 'tnum';
                font-feature-settings: 'tnum';
        position: relative;
        display: inline-block;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        min-width: 10px !important;
        height: 50px !important;
        line-height: 2px;
        vertical-align: middle;
        background-color: rgb(107, 107, 107) !important;
        border: 0;
        border-radius: 100px;
        cursor: pointer;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
      }
      .ant-switch:focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
                box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      }
      .ant-switch-checked:focus {
        -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
                box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
      .ant-switch:focus:hover {
        -webkit-box-shadow: none;
                box-shadow: none;
      }
      .ant-switch-checked {
        background-color: #179BBF !important;
      }
      .ant-switch-handle {
        position: absolute;
        top: 2px !important;
        left: 1px;
        width: 44px !important;
        height: 44px !important;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
      .ant-switch-handle::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        border-radius: 50px !important;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
                box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        content: '';
      }
      .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 45px - 2px) !important;
      }


    .post-list {
        margin-top: 2rem !important;
    }

    .agenda__space_top {
        margin-top: 3rem ;
    }

    .image04 {
        width: 100%;
        overflow: hidden;
        position: relative;
        -webkit-box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
        -moz-box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
        box-shadow: -1px 13px 56px -12px rgba(0,0,0,0.42);
        border-radius: 0px !important;
    }
    .image04 img {
        width: 100%;
        top: 0;
        position: relative;
        left: 0;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        -o-transition: -o-transform 0.3s;
        transition: transform 0.3s;
    }
    .image04 .ovrly {
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    .image04 .buttons {
        position:absolute;
        top: 3.5%;
        right: 4.5%;
    }
    .image04 .buttons .fa {
        background: rgb(256, 256, 256);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: white !important;
        color: rgba(50, 50, 50, 0.9);
        display: inline-block;
        margin: 0 1px;
        line-height: 65px;
        font-size: 25px;
        text-align: center;
        text-decoration: none;
        width: 60px;
        height: 60px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0;
        padding-right: 0.2rem;
    }
    .image04:hover {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -webkit-box-shadow: 0px 64px 154px -2px rgba(0,0,0,0.49);
        -moz-box-shadow: 0px 64px 154px -2px rgba(0,0,0,0.49);
        box-shadow: 0px 64px 154px -2px rgba(0,0,0,0.49);
    }
    .image04:hover img {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }
    .image04:hover .ovrly {
        opacity: 1;
    }
    .image04:hover .buttons .fa {
        opacity: 1;
    }

    .copyright {
        position: fixed !important;
        bottom: 1rem !important;
        left: 1rem !important;
    }

    .dropdown__divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid rgba(0, 0, 0, 0.541) !important;
        width: 200px;
    }

    .calendar__sidebar {
        position: fixed !important;
        top: 8rem !important;
        left: 15rem !important;
    }

    .demo-icon-history {
        color: #179BBF;
        font-size: 2rem;
    }

    .container-sm {
        width: 100% !important;
    }

    .log__level {
        margin-top: 1rem;
    }

    .log__pts {
        margin-top: 1rem !important;
        background: 
            linear-gradient(119deg, 
            rgba(23,122,179,1) 0%, 
            rgba(12,61,90,1) 100%);
        color: white;
    }
    .log__pts:hover {
        background: 
            linear-gradient(119deg, 
           rgb(28, 135, 197) 0%, 
           rgb(21, 96, 139) 100%);
        color: white;
    }

    .log__balance {
        background-color: #177AB3 !important;
        color: white;
        margin-top: 4rem;
    }
    
    .tutos {
        background: transparent !important;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.541);
        -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.541);
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.541);
        width: 30rem;
    }

    .datepicker {
        border-radius: 5px !important;
        height: 3rem;
        width: 15.3rem;
        margin-left: 0.3rem !important;
        font-size: 20px !important;

    }
}

/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(255, 255, 255, 0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
    margin-left: 1rem !important;
	
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
    border: none !important; 
    margin-left: 1rem !important;
      
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
    

}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
    border: 0px solid !important;
    }

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);

}

.vh {
    height: 100vh;
}
