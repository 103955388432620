
.room {
    position: relative;
}
.room button {
    position: absolute;
    top: 0;
    right: 20px;
}
.room > h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
}

.local-participant {
/*    position: absolute;
    text-align: center;
    margin-bottom: 2em;
    width: 72%;
    height: 30%;
    padding-left: 60%;
    padding-top: 5%;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;*/
}

/*
@media (max-width: 1024px) {
    .local-participant {
        position: absolute;
        text-align: center;
        margin-bottom: 2em;
        width: 79%;
        height: 30%;
        padding-left: 64%;
        padding-top: 7%;
    }
    
}

@media (max-width: 991px) {
    .local-participant {
        position: absolute;
        text-align: center;
        margin-bottom: 2em;
        width: 89%;
        height: 30%;
        padding-left: 73%;
        padding-top: 9%;
    }
    
}
@media (max-width: 767px) {
    .local-participant {
        position: absolute;
        text-align: center;
        margin-bottom: 2em;
        width: 92%;
        height: 30%;
        padding-left: 70%;
        padding-top: 19%
    }
    
}
*/
.remote-participants video {
    position: fixed;    
    top: 25px;
  /*  min-width: 100vw;*/
    min-height: 100vh;
    margin: 1px auto;
    right: 0px;
    left: 0px;
}
/*.participant {
   /* background: #333e5a;
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px; --
    height: 100%;
    width: 100%;
    display: inline-block;
}*/
.participant:last-child {
    margin-right: 0;
}
.participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
}

.local-participant video {
    z-index: 1;
    position: fixed;
    margin-left: 0px;
    width: 82%;
    padding-left: 69%;
    right: 0;
    top: 0;
    display: block;
    /* margin-bottom: 100%; mobile*/
    min-width: 500px;
    min-height: 210px;
}

@media (max-width: 1024px) {
    .local-participant video {
        padding-bottom: 0px;
        padding-top: 15px;
        height: 300px;;
        text-align: center;
        max-width: 92%;
        min-width: 100%;
        min-height: 100%;
        padding-left: 72%;
        padding-right: 7px;
    }
    .remote-participants video {
        position: absolute;
        min-height: 100%;
        margin: 1px auto;
        left: 0px;
        right: 0px;
        width: 100%;
        top: 0px;
    }
    
}
@media (max-width: 991px) {
    .local-participant video {
        position: absolute;
        text-align: center;
        max-width: 100%;
        height: 200px;;
        padding-left: 72%;
        bottom: 0px;    
        padding-top: 15px;
        padding-bottom: 0px;
        padding-right: 10px;
    }
}
@media (max-width: 991px) {
    .remote-participants video {
        position: absolute;
        min-height: 100%;
        margin: 1px auto;
        left: 0px;
        right: 0px;
        width: 100%;
        top: 0px;
    }
}
@media (max-width: 767px) {
    .local-participant video {
        padding-bottom: 0px;
        height: 120px;
     /*   position: absolute; */
        text-align: center;
        max-width: 92%;
        min-width: 100%;
        padding-left: 72%;
        padding-right: 5px;
    }
    .remote-participants video {
        position: absolute;
        min-height: 100%;
        margin: 1px auto;
        left: 0px;
        right: 0px;
        width: 100%;
        top: 0px;
    }
}

.content {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
  }

/*video {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
    border-radius: 6px;    
}*/

.flex-bottom{
    position: absolute;
    text-align: center;
    margin-bottom: 2em;
    width: 77%;
    height: 73%;
    padding-left: 21%;
    padding-top: 40%;
}

.margin-left{
    margin-left: 40px !important;
}


.remote-participants .participant video{
    /*Puntas redondeadas*/
   /* border-radius: 25px; */
}

.mensaje span {
    padding-bottom: 10px;
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #F5CBA7
}

@media (min-width: 1200px){
   
}

@media (min-width: 992px){
    
}

@media (min-width: 768px){
   
}

@media (min-width: 576px){
   
}

.todo .participant video {
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: 100% !important;
    max-height: 100% !important;
    margin: 0px 0px !important;
}

.participant video {
    padding-top: 20px;

}